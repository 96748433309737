<!--
 * @Author: your name
 * @Date: 2022-04-01 10:49:26
 * @LastEditTime: 2022-06-22 13:41:53
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\guide\index.vue
-->
<template>
  <div class="guide">
    <div class="top" :class="{'isphone':isPhone()}"></div>
    <div class="center">
      <div class="center-top">
        <div class="center-top-left">
          <div class="left-text">设备状态</div>
        </div>
        <div class="center-top-center" @click="clkmealname">
          <div class="center-text">{{realname?"已":"未"}}实名</div>
          <img src="@/assets/images/guide/realname.png" alt="" class="ctc-img" v-if="realname">
          <img src="@/assets/images/guide/norealname.png" alt="" class="ctc-img" v-else>
        </div>
        <div class="center-top-right" @click="buysetmeal">
          <div class="right-text">{{isopen?"已":"未"}}开通</div>
          <img src="@/assets/images/guide/open.png" alt="" class="ctr-img" v-if="isopen">
          <img src="@/assets/images/guide/noopen.png" alt="" class="ctr-img" v-else>
        </div>
      </div>
      <div class="center-addcard">
        <div class="addcard">
          <div class="addcard-left">
            <div class="addlt-top">ID:<span class="span">{{iccid}}</span></div>
            <div class="addlt-bottom">ICCID:<span class="span">{{cardinfo.new_iccid}}</span></div>
          </div>
          <!-- <div class="addcard-right">
            <div class="addcar-right-center">
               <img src="@/assets/images/guide/add.png" alt="" class="img">
               添加此设备
            </div>
          </div> -->
        </div>
      </div>
      <div class="funbox">
        <div class="funbox-left">
          <div class="funbox-left-top" @click="buysetmeal()">
            <div class="funbox-left-text">
              订购套餐
            </div>
          </div>
          <div class="funbox-left-bottom">
            <div class="flb-left" @click="clkbind">
              <img src="@/assets/images/guide/bindphone.png" alt="" class="flbl-img">
              绑定手机
            </div>
            <div class="flb-left" @click="clkmealname">
                <img src="@/assets/images/guide/ismealname.png" alt="" class="flbl-img" v-if="!isRed">
               <img src="@/assets/images/guide/bindname.png" alt="" class="flbl-rimg" v-else>
               <div class="flbl-text" :class="{isflbltext:!isRed}">实名认证</div>
            </div>
          </div>
        </div>
        <div class="funbox-right">
          <div class="funbox-right-top" @click="clkMyWifi">
            <div class="frt-top">
              <p class="pone">我的设备</p>
              <p>已经绑定{{cardinfo.devices}}台</p>
              <div class="frt-topr">
                <van-icon name="arrow" size="14" color="#2c72f9"/>
              </div>
            </div>
          </div>
          <div class="funbox-right-bottom" @click="gopath('/order')"> 
            <div class="frt-topb">
              <div class="">
                <p class="ponew">我的订单</p>
                <p>订单记录</p>
              </div>
              <div class="circular">
                <van-icon name="arrow" size="14" color="#0cad6a"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wifi" @click="showqrcode = true"></div>
      <div class="logo"></div>
    </div>
      <van-popup v-model="show" round position="bottom" :style="{ height: '400px' }" closeable>
      <img src="@/assets/images/guide/bgline.png" alt="" class="line">
      <div class="popup-header">
        <img src="@/assets/images/guide/bindphone.png" alt="" class="popup-img">
        <div class="right">
          <p class="p1">为此设备绑定手机号</p>
          <p class="p2">设备绑定手机后，需实名认证才可正常使用</p>
        </div>
      </div>
      <div class="from">设备号:{{iccid}}</div>
      <input type="tel" v-model="phone" class="vipnut" placeholder="请输入手机号" maxlength="11">
      <van-cell-group class="newvipnut">
        <van-field v-model="sms" center clearable placeholder="请输入短信验证码" maxlength="6" type="tel">
        <template #button>
          <van-button size="small" type="primary" @click="sendcode" :disabled="disabled" >{{buttontext}}</van-button>
        </template>
        </van-field>
      </van-cell-group>
      <div class="button" @click="bindtel" v-if="isbuttonshow">
        确认绑定
      </div>
       <div class="newbutton" v-else>
        绑定中
      </div>
    </van-popup>
    <qrcode :showqrcode="showqrcode" @clkqrcode = "clkqrcode($event)"></qrcode>
    <confirmbox :show="buttonbox" @closebtn="closebtn" @determine="determine"></confirmbox>
    <timeToast :showToast="showToast" @clkClose = "clkClose($event)"></timeToast>
    <newswifi :show="newswifiShow" @clkOverlay="newswifiShow = !newswifiShow" @change="change" :cardinfo="cardinfo"></newswifi>
  </div>
</template>
<script>
import mixin from "@/mixin/mixin.js"
import confirmbox from "@/components/confirmbox/index.vue"
import qrcode from "@/components/qrcode/index.vue"
import timeToast from "@/components/timeToast/index.vue"
import newswifi from "@/components/newswifi/index.vue"
export default {
  data() {
    return{
      iccid:"",  // 设备id
      // cardinfo:{},  //设备的详细信息
      // offcardinfo:{},  // 官方实名状态信息 
      showqrcode:false,  // 公众号弹出层
      show:false,  // 手机号认证弹窗
      phone:"",    // 用户的ID
      sms:"",  //用户的验证码
      disabled:false,  // 获取验证码的按钮能否被点击
      buttontext:"获取验证码",  // 获取验证码按钮的文字
      isbuttonshow:true,  // 是否确认绑定
      num:60,  // 倒计时开始的时间
      buttonbox:false,  // 确定取消对话框
      showToast:true,  // 实名认证时间提示图片
      newswifiShow:false,  // 新的wifi弹窗是否显示(用于切换wifi设备)
    }
  },
  components:{
    confirmbox,
    qrcode,
    timeToast,
    newswifi
  },
  mixins:[mixin],
  async mounted(){
    this.setIccid()
   await this.getcardstate()
    this.getoffinfo()
  },
  computed:{
    // 是否实名
    realname(){
      if((this.cardinfo.ispick == 2 || this.offcardinfo.ispick == 2)){
        return true
      }else{
        return false
      }
    },
    // 是否显示红色字体请去实名
    isRed(){
      if((this.cardinfo.ispick != 1 || this.offcardinfo.ispick != 2) && this.isopen){
        return true
      }else{
        return false
      }
    },
    // 是否开通
    isopen(){
      if(this.cardinfo.buy_id != 0 && this.cardinfo.buy_id && this.cardinfo.buy_id != ""){
        return true
      }else{
        return false
      }
    }
  },
  methods:{
    // 点击我的设备
    clkMyWifi(){
      if(this.cardinfo.phone != "" && this.cardinfo.phone){
        this.newswifiShow = true
      }else{
        this.show = true
      }
    },
    // 切换wifi设备
    change(){
      this.newswifiShow = !this.newswifiShow
      this.setIccid()
      this.getcardstate()
      this.getoffinfo()
    },
    // 关闭实名认证时间提示
    clkClose($event){
      this.showToast = $event
    },
    // 点击了关闭二维码遮罩层
    clkqrcode($event){
      this.showqrcode = $event
    },
    // 点击跳转页面
    gopath(path){
      if(path == "/switchwifi"){
        if(this.cardinfo.phone == "" || !this.cardinfo.phone){
          this.show =true
          return false
        }
      }
      this.$router.push(path)
    },
    // 点击实名认证
    clkmealname(){
       // 如果还没有绑定手机号
      if(this.cardinfo.phone == "" || !this.cardinfo.phone){
        this.show =true
        return false
      }
      // 如果接口还没有返回数据
      if(JSON.stringify(this.offcardinfo) == "{}"){
        this.$toast("实名状态获取中,请稍等...")
        return false
      }
     
      if(this.offcardinfo.ispick == 2 || this.cardinfo.ispick ==2){
        this.$toast("您已经实名成功,请勿重复实名")
        return false
      }
      if((this.cardinfo.phone != "" || this.cardinfo.phone) && (this.cardinfo.ispick !=2 || this.soranginfo.ispick !=2)){
         if(!this.isopen){
          this.$toast("请先订购套餐")
         }else{
          window.location.href = this.cardinfo.real_url
         }
      }
    },
     // 关闭btnbox
    closebtn(){
      this.buttonbox = false
      this.$toast("您已取消实名认证")
    },
    // 用户点击了确定实名
    determine(){
      this.buttonbox = false
      window.location.href = this.cardinfo.real_url   
    },
    // 绑定手机号
    clkbind(){
      if(this.cardinfo.phone != "" && this.cardinfo.phone){
        this.$toast("您已经绑定过手机号了~~")
      }else{
        this.show = true
      }
    },
     // 购买套餐
    buysetmeal(){
      if(this.cardinfo.phone == "" || !this.cardinfo.phone){
        this.show = true
      }else{
        let data = {
          phone:sessionStorage.getItem("phone"),
          iccid:sessionStorage.getItem("iccid"),
          path:"/selectpack"
        }
        this.isopenid(data)
      }
    },
    // 索朗手机号绑定
    sorang(data){
      this.setRealState({data}).then(res =>{
        if(res.data.code == 1){
          this.show = false
           this.$toast("绑定成功,你将前往个人中心...")
           this.isbuttonshow = true
             let datainfo = {
               iccid:sessionStorage.getItem("iccid"),
               phone:this.phone,
               path:"/user"
             }
             sessionStorage.setItem("phone",this.phone)
             this.isopenid(datainfo)
          }
          if(res.data.code == 0){
            this.$toast(res.data.msg)
            this.show = false
            setTimeout(() =>{
               this.isbuttonshow = true
            },1000)
          }
      })
    },
    // 自己的实名接口
    userBing(data){
      this.bindPhone({data}).then(res =>{
          if(res.data.code == 1){
           this.show = false
           this.$toast("绑定成功,你将前往订购套餐...")
           this.isbuttonshow = true
             let datainfo = {
               iccid:sessionStorage.getItem("iccid"),
               phone:this.phone,
               path:"/selectpack"
             }
             sessionStorage.setItem("phone",this.phone)
             this.isopenid(datainfo)
          }
          if(res.data.code == 0){
            this.$toast(res.data.msg)
            this.show = false
            setTimeout(() =>{
               this.isbuttonshow = true
            },1000)
          }
        })
    },
    // 绑定手机号
    bindtel(){
      if(this.phone =="" || this.sms ==""){
        this.$toast("请填写验证码或手机号")
        return false
      }else{
        this.isbuttonshow = false
        let data = {
          iccid:this.iccid,
          phone:this.phone,
          code:this.sms,
          platform:1
        }
        this.cardinfo.sl_real_state == 0?this.sorang(data):this.userBing(data)
      }
    },
     // 发送验证码
    sendcode(){
      if(this.phone == ""){
        this.$toast("请输入手机号")
        return false
      }else{
        this.disabled = true
        const settitle = setInterval(() => {
          if(this.num > 0){
            this.num --
            this.buttontext = this.num+"秒" 
          }else{
            this.num = 60
            this.buttontext = "获取验证码" 
            this.disabled = false
            clearInterval(settitle)
          }
        }, 1000);
        let data = {
          iccid:this.iccid,
          phone:this.phone
        }
        this.sendCode({data}).then(res =>{
          res.data.code == 1?this.$toast.success('发送成功'):this.$toast(`${res.data.msg}`)
        })  
      }
    },
    // 取到iccid并且存到本地缓存中
    setIccid(){
      if(sessionStorage.getItem("iccid") != "null" && sessionStorage.getItem("iccid") != null && sessionStorage.getItem("iccid") != undefined && sessionStorage.getItem("iccid") != "undefined"){
        this.iccid =sessionStorage.getItem("iccid")
      }else{
        this.iccid = this.$route.query.dn
        sessionStorage.setItem("iccid",this.iccid)
      }
    },
  }
}
</script>
<style lang="less" scoped>
.guide{
  .top{
    background-image: url("~@/assets/images/guide/banner.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    height: 192px;
  }
  isphone{
    height: 190px;
  }
  .center{
    width: 345px;
    margin: -135px auto 0;
    .center-top{
      display: flex;
      margin-left: 5px;
      .center-top-left{
        width: 70px;
        height: 65px;
        background-image: url("~@/assets/images/guide/cardstate.png");
        background-size: 100% auto;
        .left-text{
          color: #889198;
          text-align: center;
          margin-top: 8px;
        }
      }
      .center-top-center{
        width: 55px;
        height: 65px;
        background-image: url("~@/assets/images/guide/bgnew.png");
        background-size: 100% auto;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-direction: column;
         .ctc-img{
           width: 25px;
           height: 23px;
           display: block;
         }
         .center-text{
          color: #ffffff;
          text-align: center;
          margin-top: 8px;
          margin-bottom: 10px;
        }
      }
      .center-top-right{
        width: 55px;
        height: 65px;
        background-image: url("~@/assets/images/guide/bgnew.png");
        background-size: 100% auto;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-direction: column;
        .ctr-img{
           width: 24px;
           height: 24px;
           display: block;
         }
         .right-text{
          color: #ffffff;
          text-align: center;
          margin-top: 8px;
          margin-bottom: 10px;
        }
      }
    }
    .center-addcard{
      background-color: #ffffff;
      height: 100px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      .addcard{
        width: 305px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        .addcard-left{
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          justify-items: center;
          .addlt-top{
            font-size: 20px;
            color: #444444;
            font-weight: 600;
            .span{
              font-family: "bahn";
            }
          }
          .addlt-bottom{
            font-size: 12px;
            color: #9ba2a2;
            margin-top: 10px;
            .span{
              font-family: "bahn";
            }
          }
        }
        .addcard-right{
          width: 110px;
          height: 40px;
          font-size: 14px;
          color: #ffffff;
          background-color: #0089ff;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          .addcar-right-center{
            display: flex;
            .img{
              width: 12px;
              display: block;
              margin-right: 4px;
            }
          }
        }
      }
    }
    .funbox{
      width: 100%;
      height: 220px;
      display: flex;
      justify-content: space-between;
      .funbox-left{
        width: 170px;
        height: 100%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        background-image: url("~@/assets/images/guide/rhserve.png");
        background-size: 100% auto;
        .funbox-left-top{
          width: 100%;
          height: 120px;
          .funbox-left-text{
            margin: 65px 0 0 20px;
            width: 70px;
            height:28px;
            background-color: #fffc00;
            font-size: 14px;
            color: #f45415;
            border-radius: 4px;
            text-align: center;
            line-height: 28px;
          }
        }
        .funbox-left-bottom{
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: space-between;
          .flb-left{
            width: 50%;
            display: flex;
            justify-items: center;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #888c9e;
            .flbl-img{
              width: 28px;
              display: block;
              margin-bottom: 10px;
            }
            .flbl-rimg{
              width: 60px;
              display: block;
              margin-bottom: 10px;
            }
            .flbl-text{
              margin-bottom: 20px;
            }
            .isflbltext{
              margin-bottom: 0px;
            }
          }
        }
      }
      .funbox-right{
        width: 170px;
        height: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .funbox-right-top{
          width: 100%;
          height: 120px;
          background-image: url("~@/assets/images/guide/mucard.png");
          background-size: 100% auto;
          .frt-top{
            margin-left: 20px;
            color: #fff;
            margin: 20px 0 0 15px;
            font-weight: 600;
            .pone{
              font-size: 18px;
              margin-bottom: 10px;
            }
            .frt-topr{
              background-color: #fff;
              width: 32px;
              height: 32px;
              border-radius: 100%;
              margin: 12px 0 0 106px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .funbox-right-bottom{
          width: 100%;
          height: 90px;
          background-image: url("~@/assets/images/guide/muorder.png");
          background-size: 100% auto;
          display: flex;
          justify-items: center;
          align-items: center;
          .frt-topb{
            width: 140px;
            height: 45px;
            margin: 0 auto;
            color: #fff;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            .ponew{
              font-size: 18px;
              margin-bottom: 10px;
            }
            .circular{
              width: 32px;
              background-color: #fff;
              height: 32px;
              border-radius: 100%;
              margin-top: 12px;
              display: flex;
              justify-items: center;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .wifi{
      background-image: url("~@/assets/images/guide/wifi.png");
      background-size: 100% auto;
      margin-top: 15px;
      width: 100%;
      height: 84px;
      margin-bottom: 20px;
    }
    .logo{
      width: 80px;
      height: 28px;
      background-image: url("~@/assets/images/guide/logo.png");
      background-size: 100% auto;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      transform: translate(-50%,0);
      box-sizing: content-box;
      bottom: 0;
      padding: 5px;
      padding-bottom: calc( 10px + constant(safe-area-inset-bottom));
      padding-bottom: calc( 10px + env(safe-area-inset-bottom));
      background-repeat: no-repeat;
    }
  }
  .line{
    height: 6px;
    width: 100%;
    display: block;
  }
  /deep/.van-popup--bottom{
    border-radius: 6px 6px 0 0 ;
  }
  .popup-header{
    width: 92%;
    margin: 0 auto 30px;
    display: flex;
    padding-top: 25px;
    align-items: center;
    justify-items: center;
    .popup-img{
      width: 40px;
      height: 40px;
      display: block;
      margin-right: 10px;
    }
    .right{
      .p1{
        font-size: 16px;
        color: #444444;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 4px;
      }
      .p2{
        font-size: 12px;
        color: #aaaaaa;
      }
    }
  }
  .from{
    width: 92%;
    height: 48px;
    background-color: #f9fcff;
    font-size: 14px;
    color: #222222;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 48px;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border: solid 1px #f1f6f9;
  }
  .vipnut{
    width: 92%;
    height: 48px;
    background-color: #f9fcff;
    font-size: 14px;
    color: #222222;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 48px;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: block;
    padding-left: 20px;
    border: solid 1px #f1f6f9;
  }
  .newvipnut{
    width: 92%;
    height: 48px;
    background-color: #f9fcff;
    font-size: 14px;
    color: #222222;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 48px;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: block;
    border: solid 1px #f1f6f9;
    padding-left: 5px;
    /deep/.van-cell{
      background-color: #f9fcff;
      .van-button--primary{
        font-size: 14px;
        color: #0882e9;
        background-color: #f9fcff;
        border:none;
        border-left: solid 1px #edeff1;
      }
    }
  }
  .button{
    width: 92%;
    height: 48px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    background-color: #0786f2;
    margin-top: 30px;
  }
  .newbutton{
    width: 92%;
    height: 48px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    background-color: #aaaaaa;
    margin-top: 30px;
  }
  /deep/.van-skeleton{
    margin-top: 20px;
  }
}
</style>